export enum AppPage {
    Dashboard = 'Dashboard',
    Companies = 'Companies',
    Payments = 'Payments',
    Offers = 'Offers',
    Requests = 'Requests',
    Plans = 'Plans',
    SmartDeals = 'SmartDeals',
    CommodityTokens = 'CommodityTokens',
    CommodityTokensSettings = 'CommodityTokensSettings',
    InvestPools = 'InvestPools',
    Schedules = 'Schedules',
    Settings = 'Settings',
    UnderConstruction = 'UnderConstruction'
}