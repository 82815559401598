import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomSheetLayout from "../../../components/BottomSheetLayout/BottomSheetLayout";
import { blockchainApi } from "../../../http/blockchain.api";
import BlockchainEventsModalProvider from "../providers/BlockchainEventsModalProvider";
import { BlockchainTxUpdatedNotification, SmartDeal } from "../types";
import SmartDealDocuments from "./SmartDealDocuments";
import SmartDealInfo from "./SmartDealInfo";
import SmartDealPayments from "./SmartDealPayments";
import { useDebounce } from "usehooks-ts";
import { useWs } from "../../../providers/WsProvider";
import { BlockchainNotificationType } from "../../../types";

interface IProps {
  id: string;
  onClose: () => void;
}

export default function SmartDealPage({ id, onClose }: IProps) {
  const [tab, setTab] = useState("0");
  const [smartDeal, setSmartDeal] = useState<SmartDeal>(null);
  const navigate = useNavigate();
  const { onBlockchainEvent } = useWs();
  const [invalidateCreateDealTxObj, setInvalidateCreateDealTxObj] =
    useState<object>();
  const debouncedInvalidateCreateDealTx = useDebounce(
    invalidateCreateDealTxObj,
    1000
  );

  const getSmartDeal = async () => {
    setSmartDeal(null);
    try {
      setSmartDeal(await blockchainApi.getSmartDeal(id));
    } catch (ignored) {
      navigate(-1);
    }
  };

  const handleUpdate = (update: Partial<SmartDeal>) => {
    setSmartDeal((smartDeal) => ({
      ...smartDeal,
      ...update,
    }));
  };

  const handleInvalidateCreateDealTx = async () => {
    try {
      const createDealTx = await blockchainApi.getBlockchainTx(
        smartDeal.createDealTx.txHash
      );
      handleUpdate({ createDealTx });
    } catch {}
  };

  const handleBLockchainTxUpdatedNotification = (
    notification: BlockchainTxUpdatedNotification
  ) => {
    if (smartDeal?.createDealTx?.txHash == notification.txHash) {
      setInvalidateCreateDealTxObj({});
    }
  };

  const handleTabChange = (_, tab) => {
    setTab(tab);
  };

  useEffect(() => {
    if (debouncedInvalidateCreateDealTx) {
      handleInvalidateCreateDealTx();
    }
  }, [debouncedInvalidateCreateDealTx]);

  useEffect(() => {
    onBlockchainEvent?.on(
      BlockchainNotificationType.TxUpdated,
      handleBLockchainTxUpdatedNotification
    );

    return () => {
      onBlockchainEvent?.off(
        BlockchainNotificationType.TxUpdated,
        handleBLockchainTxUpdatedNotification
      );
    };
  }, [onBlockchainEvent, smartDeal?.createDealTx]);

  useEffect(() => {
    id && getSmartDeal();
  }, [id]);

  return (
    <BlockchainEventsModalProvider>
      <BottomSheetLayout
        title={`Smart Deal ${smartDeal?.refId || ""}`}
        onClose={onClose}
      >
        <div className="p-4 centered">
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  className="normal-case"
                  label="Deal Information"
                  value="0"
                />
                <Tab className="normal-case" label="Documents" value="1" />
                <Tab className="normal-case" label="Payments" value="2" />
              </TabList>
            </Box>

            {smartDeal ? (
              <>
                <TabPanel value="0">
                  <SmartDealInfo
                    smartDeal={smartDeal}
                    onUpdate={handleUpdate}
                  />
                </TabPanel>

                <TabPanel value="1">
                  <SmartDealDocuments
                    smartDeal={smartDeal}
                    onUpdate={handleUpdate}
                  />
                </TabPanel>

                <TabPanel value="2">
                  <SmartDealPayments
                    smartDeal={smartDeal}
                    onUpdate={handleUpdate}
                  />
                </TabPanel>
              </>
            ) : (
              <div className="absolute left-0 right-0 top-48 flex justify-center">
                <CircularProgress color="primary" size="32px" />
              </div>
            )}
          </TabContext>
        </div>
      </BottomSheetLayout>
    </BlockchainEventsModalProvider>
  );
}
