import { Edit, MoreVert, PlayArrow } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import TransactionStatusChip from "../../../components/TransactionStatusChip/TransactionStatusChip";
import { useModal } from "../../../providers/ModalProvider";
import { Schedule } from "../../../types";
import { AppPage } from "../../../types/app-page.type";
import { AppRefreshStatus } from "../../../types/app-refresh-status.type";
import {
  ScheduleNameText,
  getCronExpressionText,
  getEnableOrDisableText,
  toYesOrNoText,
} from "../../../utils/coimex-utils";
import { renderIsActiveIcon } from "../../../utils/tsx-utils";
import { appActions } from "../../app/app.state";
import {
  forceRunScheduleAction,
  updateScheduleAction,
} from "../schedules.effects";
import {
  selectIsGetSchedulesPending,
  selectPendingForceRunScheduleId,
  selectPendingUpdateScheduleId,
  selectSchedules,
} from "../schedules.selectors";
import EditSchedule from "./EditSchedule";

export default function SchedulesTable() {
  const schedules = useSelector(selectSchedules);
  const isGetSchedulesPending = useSelector(selectIsGetSchedulesPending);
  const pendingForceRunScheduleId = useSelector(
    selectPendingForceRunScheduleId
  );
  const pendingUpdateScheduleId = useSelector(selectPendingUpdateScheduleId);
  const [rows, setRows] = useState([]);
  const [selectedMenuSchedule, setSelectedMenuSchedule] =
    useState<Schedule | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const openModal = useModal();
  const dispatch = useDispatch();

  const handleForceRunSchedule = async () => {
    closeMenu();
    dispatch(forceRunScheduleAction({ id: selectedMenuSchedule!.id }));
  };

  const handleToggleScheduleActive = async () => {
    closeMenu();
    dispatch(
      updateScheduleAction({
        id: selectedMenuSchedule!.id,
        update: { isActive: !selectedMenuSchedule?.isActive },
      })
    );
  };

  const handleEditSchedule = async () => {
    closeMenu();
    if (
      await openModal((props) => (
        <EditSchedule schedule={selectedMenuSchedule!} {...props} />
      ))
    ) {
      dispatch(
        appActions.changeRefresh({
          page: AppPage.Schedules,
          status: AppRefreshStatus.Invalidated,
        })
      );
    }
  };

  const showMenu = (
    e: React.MouseEvent<HTMLButtonElement>,
    schedule: Schedule
  ) => {
    e.stopPropagation();
    setSelectedMenuSchedule(schedule);
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    setRows(
      schedules?.map((schedule, index) => ({
        ...schedule,
        rowNo: index + 1,
      })) as any
    );
  }, [schedules]);

  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: "rowNo",
        headerName: "",
        width: 50,
        sortable: false,
      },
      {
        field: "name",
        headerName: "Name",
        renderCell: (params) => (
          <Tooltip title={ScheduleNameText[params.value]}>
            <span>{ScheduleNameText[params.value]}</span>
          </Tooltip>
        ),
        flex: 1,
        sortable: false,
      },
      {
        field: "croneExpression",
        headerName: "Runs At",
        renderCell: (params) => (
          <Tooltip title={getCronExpressionText(params.value)}>
            <span>{getCronExpressionText(params.value)}</span>
          </Tooltip>
        ),
        flex: 1,
        sortable: false,
      },
      {
        field: "isActive",
        headerName: "Enabled",
        valueFormatter: (params) => toYesOrNoText(params.value),
        width: 80,
        sortable: false,
      },
      {
        field: "lastRunStatus",
        headerName: "Last Run",
        renderCell: (params) =>
          params.row.id === pendingForceRunScheduleId ? (
            <Loading size={20} />
          ) : params.value ? (
            <TransactionStatusChip
              status={params.value}
              message={params.row.logs}
            />
          ) : (
            <></>
          ),
        width: 120,
        sortable: false,
      },
      {
        field: "lastRunAt",
        headerName: "Last Run At",
        valueFormatter: (params) =>
          params.value ? moment(params.value).fromNow() : "",
        flex: 1,
        sortable: false,
      },
      {
        field: "lastSuccessfulRunAt",
        headerName: "Last Succeeded",
        valueFormatter: (params) =>
          params.value ? moment(params.value).fromNow() : "",
        flex: 1,
        sortable: false,
      },
      {
        field: "",
        renderCell: (params) => (
          <IconButton onClick={(e) => showMenu(e, params.row)}>
            <MoreVert />
          </IconButton>
        ),
        width: 60,
        sortable: false,
      },
    ],
    [pendingForceRunScheduleId]
  );

  return (
    <div className="w-full mt-4">
      <DataGrid
        columns={cols}
        rows={rows}
        disableColumnMenu
        disableSelectionOnClick
        style={{ minHeight: "300px", height: "calc(100vh - 151px)" }}
        loading={isGetSchedulesPending}
        pagination
        paginationMode="client"
        autoPageSize
        components={{
          NoRowsOverlay: () => <NoRowsOverlay emptyMessage="No schedules" />,
        }}
      />
      <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
        <div className="flex flex-col px-1">
          <LoadingButton
            className="justify-start"
            color="info"
            variant="text"
            startIcon={<PlayArrow />}
            loading={pendingForceRunScheduleId === selectedMenuSchedule?.id}
            onClick={handleForceRunSchedule}
          >
            Run now
          </LoadingButton>
          <Divider />
          <LoadingButton
            className="justify-start"
            color={selectedMenuSchedule?.isActive ? "error" : "success"}
            variant="text"
            startIcon={renderIsActiveIcon(!selectedMenuSchedule?.isActive)}
            loading={pendingUpdateScheduleId === selectedMenuSchedule?.id}
            onClick={handleToggleScheduleActive}
          >
            {getEnableOrDisableText(!selectedMenuSchedule?.isActive)}
          </LoadingButton>
          <Divider />
          <Button
            className="justify-start"
            variant="text"
            startIcon={<Edit />}
            onClick={handleEditSchedule}
          >
            Edit
          </Button>
        </div>
      </MoreMenu>
    </div>
  );
}
